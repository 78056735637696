import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BirdsData from './BirdsData.json'

// Componentes de aframe
import '../../components/aframe-components/CustomGLBMaterial.js'
import '../../components/aframe-components/SwapUvsComponents.js'
import '../../components/aframe-components/MindarImageControllerComponent.js';
import '../../components/aframe-components/HideNodesGLBComponent.js'

import LoadingIndicator from '../../components/react-components/LoadingIndicator.js';
import ARCloud from '../AugmentedReality/AfichesAR/ARScenes/ARCloud.js';
import ARInfomationModal from '../AugmentedReality/AfichesAR/ARInformationModal.js';
import ARInstructionsModal from '../AugmentedReality/AfichesAR/ARInstructionsModal.js';

const anim_spawn = "property: scale; from:0 0 0; to: 1 1 1; dur: 500; easing: easeInOutQuad;elasticity:1000; loop: false; dir: alternate; startEvents: spawn;"
const anim_close = "property: scale; from:1 1 1; to: 0 0 0; dur: 500; easing: easeInOutQuad; loop: false; dir: alternate; startEvents: close;"
const anim_spawn_ar_container = "property: scale; from:0 0 0; to: 1 1 1; dur: 1000; easing: easeOutElastic;elasticity:1000; loop: false; dir: normal; startEvents: spawnar;"

const ARPoemsBook = () => {
  const navigate = useNavigate();

    // Obtengo el id del ave de la url
    let { aveId } = useParams();

    const sceneRef = useRef(null);
    const videoRef = useRef(null);
    const arSystemRef = useRef(null);
    const [arReady, setArReady] = useState(false);
    const [birdData, setBirdData] = useState(null);
    const [targetFounded, setTargetFounded] = useState(false);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const getBirdInformation = () => {
        if (!aveId) {
            navigate('/')
            return
        }

        const bird_data = BirdsData.aves.find(e => e.id == aveId)
        if (!bird_data) {
            navigate('/')
            return
        }
        setBirdData(bird_data);

        // Inicio la escena de AR
        const sceneEl = sceneRef.current;
        sceneEl.setAttribute("mindar-image",
            {
                imageTargetSrc: "../../" + process.env.PUBLIC_URL + "/ar_image_targets/" + bird_data.ar_target,
                autoStart: false,
                uiLoading: "no",
                uiError: "no",
                uiScanning: "no",
                filterMinCF: 0.0001,
                filterBeta: 0.01,
                warmupTolerance: 1,
                missTolerance: 3
            }
        )
        arSystemRef.current = sceneEl.systems["mindar-image-system"];
        arSystemRef.current.start();
    }

    const repositionVideo = () => {
        const video = videoRef.current;
        const sceneEl = sceneRef.current;

        sceneEl.resize();
        if (video) {
            setTimeout(() => {
                video.style.left = 0;
                video.style.top = 0;
                video.style.objectFit = "cover";

                video.style.width = '100%';
                video.style.height = '100%';
            }, 200);

        }
    }

    const onTargetFound = () => {
        setTargetFounded(true);
    }

    const onTargetLost = () => {
        setTargetFounded(false);
    }

    useEffect(() => {
        const sceneEl = sceneRef.current;

        const handleRenderStart = () => {
            // Valido que exista información del ave para consultarla
            getBirdInformation()
        }

        const handleARReady = (event) => {
            // sceneEl.resize()

            videoRef.current = document.querySelector('video');
            //SOLUCIÓN TEMPORAL PARA VIDEO AR EN MÓVIL
            repositionVideo();

            setArReady(true);
        }

        // detect target found
        sceneEl.addEventListener("targetFound", onTargetFound);
        // detect target lost
        sceneEl.addEventListener("targetLost", onTargetLost);

        sceneEl.addEventListener('renderstart', handleRenderStart);
        sceneEl.addEventListener('arReady', handleARReady);
        window.addEventListener("resize", repositionVideo);

        return () => {
            if (arSystemRef.current) {
                arSystemRef.current.stop();
            }
            sceneEl.removeEventListener('targetFound', onTargetFound);
            sceneEl.removeEventListener("targetLost", onTargetLost);
            sceneEl.removeEventListener('renderstart', handleRenderStart);
            sceneEl.removeEventListener('arReady', handleARReady);
            window.removeEventListener("resize", repositionVideo);
        };
    }, []);


    return (
        <div className='w-full h-full fixed'>
            {/* {targetFounded && <ARScreenshotView />} */}
            {!arReady && <LoadingIndicator />}
            {arReady && !openModalInformation && <ARInstructionsModal 
                open={!targetFounded} 
                image={process.env.PUBLIC_URL + '/ar_image_targets/' + birdData?.foto}
                text="Por favor apunta la cámara hacia la foto del ave"
            />}

            {/* <ARInfomationModal
                open={true}
                setOpen={setOpenModalInformation}
                title={birdData?.nombre_comun}
                image={process.env.PUBLIC_URL + '/ar_image_targets/' + birdData?.foto}
                description={birdData?.descripcion}
            /> */}

            {
                arReady && birdData &&
                <ARInfomationModal
                    open={openModalInformation}
                    visibleButton={targetFounded}
                    setOpen={setOpenModalInformation}
                    title={birdData.nombre_comun}
                    image={process.env.PUBLIC_URL + '/ar_image_targets/' + birdData.foto}
                    description={birdData.descripcion}
                />
            }
            
            <a-scene ref={sceneRef}
                color-space="sRGB"
                renderer="colorManagement: true, physicallyCorrectLights"
                xr-mode-ui="enabled: false"
                device-orientation-permission-ui="enabled: false"
            >
                <a-assets>
                    {/* <img id="card" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/card.png" /> */}
                    <a-asset-item id="avatarModel" src="/models/Wood.glb"></a-asset-item>
                </a-assets>

                <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>
                {birdData &&
                    <a-entity mindar-image-target="targetIndex: 0" mindar-image-controller="container:#contenedor-0">

                        <a-entity
                            id="contenedor-0"
                            position="0 0 0"
                            rotation="0 0 0"
                            animation__spawn={anim_spawn_ar_container}
                        >
                            <a-gltf-model
                                position={birdData.transform?.ave?.position}
                                rotation={birdData.transform?.ave?.rotation}
                                scale={birdData.transform?.ave?.scale}
                                src={process.env.PUBLIC_URL + "/models/" + birdData.modelo}
                                visible="true"
                                animation-mixer={`clip:${birdData.animaciones.foto}; crossFadeDuration:0.2`}
                                hide-nodes={`nodes:${birdData.ocultar ? birdData.ocultar : ""};`}
                                custom-glb-material={`
                                transparent: false; 
                                ${birdData.material?.map ?
                                        `map: ${process.env.PUBLIC_URL + birdData.material.map};` : ""}
                                ${birdData.material?.normalMap ?
                                        `normalMap: ${process.env.PUBLIC_URL + birdData.material.normalMap};` : ""}
                                ${birdData.material?.metalnessMap ?
                                        `metalnessMap: ${process.env.PUBLIC_URL + birdData.material.metalnessMap};` : ""}
                                ${birdData.material?.roughnessMap ?
                                        `roughnessMap: ${process.env.PUBLIC_URL + birdData.material.roughnessMap};` : ""}
                                ${birdData.material?.metalness ? `metalness: ${birdData.material.metalness};` : ""}
                                ${birdData.material?.roughness ? `roughness: ${birdData.material.roughness};` : ""}
                                ${birdData.material?.transparent ? `transparent: ${birdData.material.transparent};` : ""}
                                ${birdData.material?.opacity ? `opacity: ${birdData.material.opacity};` : ""}
                                ${birdData.material?.alphaTest ? `alphaTest: ${birdData.material.alphaTest};` : ""}
                                ${birdData.material?.swapUvs ? `swapUvs: ${birdData.material.swapUvs};` : ""}
                            `}></a-gltf-model>

                            {   birdData.transform?.base?.position &&
                                birdData.transform?.base?.rotation &&
                                birdData.transform?.base?.scale &&
                                <a-gltf-model
                                    position={birdData.transform?.base?.position}
                                    rotation={birdData.transform?.base?.rotation}
                                    scale={birdData.transform?.base?.scale}
                                    src={process.env.PUBLIC_URL + "/models/Wood.glb"}
                                    visible="true"
                                    hide-nodes="nodes: Group1, Group2, Group4, Group5, Group6, Group7, Group8, Group9, Group10"
                                    custom-glb-material={`
                                    transparent: false;
                                    metalness: 0;
                                    roughness: 1;
                                    opacity: 1;
                                `}
                                >
                                </a-gltf-model>
                            }

                            {birdData.extras &&

                                birdData.extras.map((extra, i) =>
                                    <ARCloud
                                        key={i}
                                        position={extra.position}
                                        rotation={extra.rotation}
                                        scale={extra.scale}
                                        type={extra.tipo}
                                    />
                                )
                            }


                        </a-entity>

                    </a-entity>
                }
            </a-scene>
        </div>
    )
}

export default ARPoemsBook;